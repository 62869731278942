//import {Cesium3DTileset,Viewer} from 'cesium';
declare var Cesium: any
import { GUI } from 'dat.gui';
import { v4 as uuidv4 } from 'uuid';
import { Subject } from 'rxjs';
const defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzZmY3NmU1Zi1hMDgyLTQxMzgtOWE5OC0wNmYzNjNhMWFlNzIiLCJpZCI6MTE1MDU3LCJpYXQiOjE2Njg1ODY3Mzd9.LROUpmSdA0UFBZHTpNSccT_L5rdE-SIc9IjUfiUDbCk";
Cesium.Ion.defaultAccessToken = defaultAccessToken;
export class CesiumViewer {
  settingsChanges = new Subject();
  el;
  state;
  options;
  viewer;
  zone;
  toolBox = '';
  markers = [];
  constructor(el, options, zone) {
    this.el = el;
    this.zone = zone;
    this.options = options;
    this.state = {
      ...{
        grid: this.options.grid || false,
        label: 0.2,
        bgColor: '#e1e1e1',
      }, ...{
        ...this.options.modelOptions
      },
      coordinate: ''
    };

    this.viewer = new Cesium.Viewer(this.el, {
      // globe: false,
      skyAtmosphere: false,
      skyBox: false,
      animation: false,
      timeline: false,
      vrButton: false,
      fullscreenButton: false,
      geocoder: false,
      homeButton: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      depthTestAgainstTerrain: true
      //  navigationHelpButton: false,
      //  shouldAnimate: false,
    });
    this.viewer.scene.globe.depthTestAgainstTerrain = true;
    this.viewer.bottomContainer.style.display = 'none';
    this.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 500;
    this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 1;



  }

  addGUI() {

    const _this = this;
    const guiWrap = document.createElement('div');
    const gui = new GUI({ autoPlace: false, width: 260, hideable: true });
    // // Display controls.
    // const bgColor1Ctrl = gui.addColor(this.state, 'bgColor').name('Background');
    // bgColor1Ctrl.onChange(() => this.updateBackground());
    if (!this.options.sideBySide) {
      const labelsCtrl = gui.add(this.state, 'label', 0.01, 3).name('Label width');
      labelsCtrl.onChange(() => this.labelCtrlChange());
      const bgColor1Ctrl = gui.addColor(this.state, 'bgColor').name('Background');
      bgColor1Ctrl.onChange(() => this.updateBackground());
      var saveBtn = {
        add: function () {
          _this.settingsChanges.next('update');
        }
      };
      gui.add(saveBtn, 'add').name('Save settings');

      var resetView = {
        add: async function () {
          await _this.viewer.zoomTo(_this.tileset);
          _this.settingsChanges.next('reset');
        }
      };
      gui.add(resetView, 'add').name('Reset View');
    }

    var zoomResetBtn = {
      add: async function () {
        await _this.viewer.zoomTo(_this.tileset);
        _this.setCameraPositions(_this.options.modelOptions)
      }
    };
    gui.add(zoomResetBtn, 'add').name('Home');

    this.el.parentElement.appendChild(guiWrap);
    guiWrap.classList.add(this.options.class ? this.options.class : 'gui-wrap');

    guiWrap.classList.add(this.options.class ? this.options.class : 'gui-wrap');
    guiWrap.appendChild(gui.domElement);
  }

  updateBackground() {
    this.viewer.scene.backgroundColor = Cesium.Color.fromCssColorString(this.state.bgColor);
  }

  labelCtrlChange() {
    const entitiesArray = this.viewer.entities.values;
    // Iterate over the entities using a for loop
    for (let i = 0; i < entitiesArray.length; i++) {
      const entity = entitiesArray[i];
      if (entity && entity.billboard)
        entity.billboard.scale = entity.name == 'points' ? this.state.label / 2 : this.state.label;
    }
  }

  resize(height, width) {
    const panel = this.options.id;
    panel.style.height = height + 'px';
    panel.style.width = width + 'px';
    this.viewer.resize();
  }


  tileset;
  tileset2;
  async loadTiles(model, meunOptions = true) {

    this.tileset = await Cesium.Cesium3DTileset.fromUrl(model.tilesUrl,
      {
        skipLevelOfDetail: true,
        baseScreenSpaceError: 1024,
        skipScreenSpaceErrorFactor: 16,
        skipLevels: 1,
        immediatelyLoadDesiredLevelOfDetail: true,
        loadSiblings: false,
        cullWithChildrenBounds: true,
        dynamicScreenSpaceError: true,
        dynamicScreenSpaceErrorDensity: 0.00278,
        dynamicScreenSpaceErrorFactor: 4.0,
        dynamicScreenSpaceErrorHeightFalloff: 0.25
      }
    );
    this.resize(this.el.height, this.el.width);
    var targetCartographic = Cesium.Cartographic.fromDegrees(-105, 35, 0);
    var targetCartesian = Cesium.Cartographic.toCartesian(targetCartographic);
    this.tileset.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(targetCartesian);
    this.viewer.scene.globe.show = false;
    const toolBtns: any = document.getElementsByClassName('cesium-toolbar-button');
    for(var i=0;i<toolBtns?.length;i++){
      if(toolBtns[i].title?.includes('Bing Maps')){
        toolBtns[i].hidden=true;
      }
    }
   
    this.zone.runOutsideAngular(async () => {
      try {
        await this.viewer.scene.primitives.add(this.tileset);
        await this.viewer.zoomTo(this.tileset);
        this.addGUI();
        this.setCameraPositions(this.options.modelOptions);
        this.updateBackground();

      } catch (ex) {
        console.log('error')
      }
    })



    // var measureWidget = new Cesium.Measure({
    //     container: 'cesiumContainer',
    //     scene: viewer.scene,
    //     units: new Cesium.MeasureUnits({
    //         distanceUnits: Cesium.DistanceUnits.METERS,
    //         areaUnits: Cesium.AreaUnits.SQUARE_METERS,
    //         volumeUnits: Cesium.VolumeUnits.CUBIC_FEET,
    //         angleUnits: Cesium.AngleUnits.DEGREES,
    //         slopeUnits: Cesium.AngleUnits.GRADE
    //     })
    // });
  }

  remove() {
    this.viewer.destroy();
  }

  removeAll() {
    const entitiesArray = this.viewer.entities.values.filter(o => o.name === 'points');
    entitiesArray.forEach(el => {
      this.viewer.entities.removeById(el.id)
    });
    this.markers = [];
  }


  deleteByIndex(index) {
    const id = this.markers[index].id;
    const entitiesArray = this.viewer.entities.values.filter(o => o.userPoints === id);
    entitiesArray.forEach(el => {
      this.viewer.entities.removeById(el.id)
    });
    this.markers.splice(index, 1);
  }


  setCameraPositions(option) {
    if (option?.cameraPosition) {

      this.viewer.camera.setView({
        destination: option.cameraPosition,
        orientation: {
          // endTransform:new Cesium.Cartesian3(option.transform),
          direction: option.direction,
          up: option.up,
          heading: option.heading, // east, default value is 0.0 (north)
          pitch: option.pitch,    // default value (looking down)
          roll: option.roll                          // default value
        }
      });
    }
  }

  async createCircle(pickedPosition, id, color) {
    const position = Cesium.Cartesian3.fromElements(
      pickedPosition.x,
      pickedPosition.y,
      pickedPosition.z
    );

    const canvas = await this.getBubble(color);
    const entity = this.viewer.entities.add({
      position: position,
      billboard: {
        image: canvas,
        scale: this.state.label / 2,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        clampToGround: true,
        //  scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
        translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      },
      name: 'points',
      userPoints: id,
    })
    return {
      entity: entity,
      position: position
    }
  }

  async loadDistances(values) {
    values.forEach(value => {
      const clickedPoints = [];
      value.points.forEach(async point => {
        const pickedPosition = Cesium.Cartesian3.fromElements(
          point.x,
          point.y,
          point.z
        );
        clickedPoints.push(pickedPosition);
        await this.createCircle(pickedPosition, value.id, "red");
      });
      this.createLine(clickedPoints, value.id);
      // Calculate the center point of the positions
      var centerPoint = this.calculateCenterPoint(clickedPoints);
      // Add a label at the center point
      const text = Cesium.Cartesian3.distance(clickedPoints[0], clickedPoints[1]).toFixed(2) + "m"
      this.addLabel(centerPoint, text, value.id);
    });
    this.markers = values;
  }

  async loadAngles(values) {
    values.forEach(value => {
      const clickedPoints = [];
      value.points.forEach(async point => {
        const pickedPosition = Cesium.Cartesian3.fromElements(
          point.x,
          point.y,
          point.z
        );
        clickedPoints.push(pickedPosition);
        await this.createCircle(pickedPosition, value.id, "blue");
      });
      this.createLine(clickedPoints, value.id);
      // Calculate the center point of the positions
      var centerPoint = this.calculateCenterPoint(clickedPoints);
      const angleDegrees = this.getAngleBetweenLines(clickedPoints[0], clickedPoints[1], clickedPoints[2]);
      const text = angleDegrees.toFixed(2) + "°"
      this.addLabel(centerPoint, text, value.id);
    });
    this.markers = values;
  }

  getAngleBetweenLines(point1, point2, point3) {
    // Calculate vectors for the two lines
    const vector1 = Cesium.Cartesian3.subtract(point2, point1, new Cesium.Cartesian3());
    const vector2 = Cesium.Cartesian3.subtract(point3, point2, new Cesium.Cartesian3());

    // Calculate the dot product
    const dotProduct = Cesium.Cartesian3.dot(vector1, vector2);

    // Calculate the magnitudes of the vectors
    const magnitude1 = Cesium.Cartesian3.magnitude(vector1);
    const magnitude2 = Cesium.Cartesian3.magnitude(vector2);

    // Calculate the angle in radians
    let angleRadians = Math.acos(dotProduct / (magnitude1 * magnitude2));

    // Convert the angle from radians to degrees
    const angleDegrees = Cesium.Math.toDegrees(angleRadians);

    return angleDegrees;
  }

  clickedPoints = [];
  id = uuidv4();
  async getDistance(movement) {
    var pickedObject = this.viewer.scene.pick(movement.position);
    var pickedPosition = this.viewer.scene.pickPosition(movement.position)
    if (pickedPosition && Cesium.defined(pickedObject)) {
      const circle: any = await this.createCircle(pickedPosition, this.id, "red");
      this.clickedPoints.push({
        entity: circle.entity,
        position: circle.position
      })
      if (this.clickedPoints.length === 2) {
        this.createLine(this.clickedPoints.map(o => o.position), this.id);
        // Calculate the center point of the positions
        var centerPoint = this.calculateCenterPoint(this.clickedPoints.map(o => o.position));
        // Add a label at the center point
        const text = Cesium.Cartesian3.distance(this.clickedPoints[0].position, this.clickedPoints[1].position).toFixed(2) + "m"
        this.addLabel(centerPoint, text, this.id);
        this.markers.push({
          title: text,
          points: [{
            x: this.clickedPoints[0].position.x,
            y: this.clickedPoints[0].position.y,
            z: this.clickedPoints[0].position.z,
          },
          {
            x: this.clickedPoints[1].position.x,
            y: this.clickedPoints[1].position.y,
            z: this.clickedPoints[1].position.z,
          }],
          id: this.id,
        })
        this.clickedPoints = [];
        this.id = uuidv4();

      }

    }
  }

  async getAngles(movement) {
    var pickedObject = this.viewer.scene.pick(movement.position);
    var pickedPosition = this.viewer.scene.pickPosition(movement.position)
    if (pickedPosition && Cesium.defined(pickedObject)) {
      const circle: any = await this.createCircle(pickedPosition, this.id, "blue");
      this.clickedPoints.push({
        entity: circle.entity,
        position: circle.position
      })
      if (this.clickedPoints.length === 3) {
        this.createLine(this.clickedPoints.map(o => o.position), this.id);
        // Calculate the center point of the positions
        const centerPoint = this.calculateCenterPoint(this.clickedPoints.map(o => o.position));
        // Add a label at the center point
        //  const angleRadians = Cesium.Cartesian3.angleBetween(this.clickedPoints[0].position, this.clickedPoints[2].position)
        // Convert the angle from radians to degrees
        const angleDegrees = this.getAngleBetweenLines(this.clickedPoints[0].position, this.clickedPoints[1].position, this.clickedPoints[2].position);
        const text = angleDegrees.toFixed(2) + "°"
        this.addLabel(centerPoint, text, this.id);
        this.markers.push({
          title: text,
          points: [{
            x: this.clickedPoints[0].position.x,
            y: this.clickedPoints[0].position.y,
            z: this.clickedPoints[0].position.z,
          },
          {
            x: this.clickedPoints[1].position.x,
            y: this.clickedPoints[1].position.y,
            z: this.clickedPoints[1].position.z,
          },
          {
            x: this.clickedPoints[2].position.x,
            y: this.clickedPoints[2].position.y,
            z: this.clickedPoints[2].position.z,
          }],
          id: this.id,
        })
        this.clickedPoints = [];
        this.id = uuidv4();

      }

    }
  }


  addLabel(position, text, id) {
    this.viewer.entities.add({
      position: position,
      label: {
        text: text,
        font: '14px sans-serif',
        fillColor: Cesium.Color.BLACK,
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 2,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        clampToGround: true,
        scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
        translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      },
      name: 'points',
      userPoints: id
    });
  }

  calculateCenterPoint(positions) {
    var boundingSphere = Cesium.BoundingSphere.fromPoints(positions);
    return boundingSphere.center;
  }

  createLine(points, id) {
    this.viewer.entities.add({
      polyline: {
        positions: points,
        width: 1,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        clampToGround: true,
        scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
        material: Cesium.Color.BLACK
      },
      name: 'points',
      userPoints: id
    });
  }
  public async getBubble(color: string = '#22222') {
    var canvas = document.createElement("canvas");
    canvas.width = 40;
    canvas.height = 40;
    var context = canvas.getContext("2d");
    context.beginPath();
    context.fillStyle = color;
    context.strokeStyle = "black";
    context.font = "15px Georgia";
    context.lineWidth = 1;
    context.arc(20, 20, 20, 0, 2 * Math.PI, false);
    context.fill();
    return canvas;
  }

}


