<div class="popup-header w-100 d-flex">
    <p class="w-100">Create Campaign</p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<div>
    <div mat-dialog-content class="mb-5">
        <div class="form-field">
            <label for="input-role">Campaign Name <span class="required-field">*</span></label>
            <br>
            <mat-form-field appearance="fill" id="input-role" class="w-100 mb-2">
                <input matInput cdkFocusInitial [(ngModel)]="name" />
            </mat-form-field>
        </div>

        <div class="form-field">
            <div class="col-12" style="padding: 0;">
                <label class="form-label"> Company Name <span class="required-field">*</span>
                </label>
            </div>
            <mat-form-field appearance="fill" class="w-100">
                <mat-select [(ngModel)]="companyId" (selectionChange)="filterAssets($event)">
                    <mat-option style="height:auto" *ngFor="let company of companies" [value]="company.companyId">
                        {{company.companyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-field">
            <div class="col-12" style="padding: 0;">
                <label class="form-label"> Select Assets <span class="required-field">*</span>
                </label>
            </div>

            <mat-form-field appearance="fill" class="w-100">
                <mat-select multiple [(ngModel)]="selectedAssets">
                    <mat-option *ngFor="let asset of companyAssets" [(value)]="asset.id">{{asset.assetName}}
                    </mat-option>
                    <mat-option *ngIf="!companyAssets.length" disabled>No assets found for this use.</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <br>
        <div class="form-field">
            <label for="input-role">Campaign Start Date</label>
            <br>
            <mat-form-field appearance="fill" id="input-role" class="w-100 mb-2">
                <input type="date" matInput cdkFocusInitial [ngModel]="startDate || ''" 
                (ngModelChange)="startDate = $event" 
                    placeholder="Start date of inspection" />
            </mat-form-field>
        </div>

        <div class="form-field">
            <label for="input-role">Campaign End Date</label>
            <br>
            <mat-form-field appearance="fill" id="input-role" class="w-100 mb-2">
                <input type="date" matInput cdkFocusInitial [ngModel]="endDate || ''" 
                (ngModelChange)="endDate = $event" 
                    placeholder="End date of inspection" />
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions>
        <mat-spinner-button [options]="createBtnOptions" (btnClick)="handleCreate()"
            [disabled]="!name || !selectedAssets.length || !startDate || !endDate  "></mat-spinner-button>
        <button class="ml-2" mat-button (click)="close()">Cancel</button>
    </div>

</div>