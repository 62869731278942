export enum inferenceEngineMode {
  Show = 1,
  Hide = 2,
  Beta = 3
}
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDq9CHuCBumdVh3ZopnILW4_EwaFjU7AZA',
    authDomain: 'dive.oceansai.tech',
    databaseURL: 'https://dive-production-293811.firebaseio.com',
    projectId: 'dive-production-293811',
    storageBucket: 'dive-production-293811.appspot.com',
    messagingSenderId: '108709742267',
    appId: '1:108709742267:web:fb9fd37b186d502f150b9e'
  },
  appName: 'OIM',
  baseAPIUrl: 'https://pdf-and-docx-generator-7m6tmx2e3q-oa.a.run.app',
  baseURL: 'https://diveintegration-api-7m6tmx2e3q-as.a.run.app',
  engineURL: 'https://diveintegration-api-7m6tmx2e3q-as.a.run.app/ai_trigger_process/',
  imageSimilarity: 'https://diveside-by-side-7m6tmx2e3q-as.a.run.app/dive-image-similarity/',
  engineMode: inferenceEngineMode.Hide,
  timeline: true,
  QRexport: 'https://qr-automation-7m6tmx2e3q-as.a.run.app//generate-qrcode',
  objToTiling: 'https://generate-3d-7m6tmx2e3q-as.a.run.app/tiling_engine/',
  threeDModel: 'https://generate-3d-7m6tmx2e3q-as.a.run.app/3d_model_engine/',
  cancelThreeDModel: 'https://generate-3d-7m6tmx2e3q-as.a.run.app/cancel_process/',
  cropping: 'https://polygon-cropping-7m6tmx2e3q-as.a.run.app/polygon_cropping/',
  alignment: `https://model-alignment-7m6tmx2e3q-as.a.run.app//model_alignment_task/`,
  thermal: `https://thermal-process-7m6tmx2e3q-as.a.run.app/`,
  dxfUrl: 'https://dxf-drawing-7m6tmx2e3q-as.a.run.app/',
  logProcessUrl: 'https://process-logfile-7m6tmx2e3q-as.a.run.app//process_logfiles/',
  imagePolygons:' https://locateimagesinpolygon-7m6tmx2e3q-as.a.run.app//get_images_in_polygon/',
  fetchAnnotations:  'https://dive-python-general-7m6tmx2e3q-as.a.run.app/',
  defaultTemplateLocation: `templates/default/Default-template.docx`,

  modelSize: 600,
  severityLevels: [{
    level: 1,
    color: '#b4e6fd'
  }, {
    level: 2,
    color: '#5cbfed'
  }, {
    level: 3,
    color: '#007eb9'
  }, {
    level: 4,
    color: '#fdeb69'
  }, {
    level: 5,
    color: '#f7dd25'
  }, {
    level: 6,
    color: '#e39a16'
  }, {
    level: 7,
    color: '#c7953a'
  }, {
    level: 8,
    color: '#f17c7c'
  }, {
    level: 9,
    color: '#ff4f4f'
  }, {
    level: 10,
    color: '#d30808'
  }]
};

