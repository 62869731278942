import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { BackendService } from '../services/backend.service';
import { Project } from './item/project.type';
import { ProjectsAddDialogComponent } from './add-dialog/add-dialog.component';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { UiService } from '../services/ui.service';
import { GeneralService } from '../services/general.service';
import { IProject } from '../models/project.interface';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Router } from '@angular/router';
declare var google: any;
import { CalendarOptions } from '@fullcalendar/angular';
import { Overlay } from '@angular/cdk/overlay';
import { AddCampaignDialogeComponent } from './add-campaign/add-campaign.component';
@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy, AfterViewChecked {
  public projects: Project[] = [];
  public assets = [];
  public filteredProjects: Project[] = [];
  public companyAssets = [];
  public search = '';
  @ViewChild('deleteDialog') deleteDialog: any;
  @ViewChild('renameDialog') renameDialog: any;
  @ViewChild('calendar') calendar: any;
  @ViewChild('projectSummary') projectSummaryDialog: any;

  public projectNameInput: string;
  deleteDialogRef;
  renameDialogRef;
  public summaryDialogRef;
  deleteDialogSubcription = new Subscription();
  renameDialogSubcription = new Subscription();

  isRefreshProjectsLoading$ = new BehaviorSubject<boolean>(false);
  isProjectsLoading$ = new BehaviorSubject<boolean>(false);
  private onDestroy$ = new Subject();
  assetSidePanelView: string = 'panel';
  firstLoad = false;
  projectSidePanelView: string = 'panel';
  @ViewChild('mapProjectEl') mapElement: any;
  private map: any;
  private markers: any = [];
  assetMapData: boolean = true;
  calendarEvents = [];
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    height: '95%',
    editable: false,
    headerToolbar: {
      left: 'prev,next today dayGridMonth,timeGridWeek,listYear',
      center: 'title',
      right: '',

    },
    buttonText: {
      today: 'today',
      month: 'month',
      week: 'week',
      day: 'day',
      list: 'year'
    },
    events: [],
    eventClick: this.mouseEnter.bind(this),
    // eventMouseLeave: this.mouseLeave.bind(this)
  };
  constructor(
    public dialog: MatDialog,
    private confirmDialog: WaMatConfirmDialog,
    public uiService: UiService,
    private generalService: GeneralService,
    public backendService: BackendService,
    private toast: ToastrService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private overlay: Overlay

  ) {
    this.uiService.activeFolder = null;

  }

  mouseEnter(cmp) {
    const assetId = cmp.event.id;
    const asset = this.assets.find(o => o.id === assetId);
    const projects = this.projects.filter(o => o.assetId === assetId);
    this.summaryDialogRef = this.dialog.open(this.projectSummaryDialog, {
      data: { asset: asset, projects: projects },
    });
  }


  ngOnInit(): void {
    this.isProjectsLoading$.next(true)

  }

  projectChange(projects) {
    this.filteredProjects = projects.sort((a, b) => new Date(b?.inspectionDate).getTime() - new Date(a?.inspectionDate).getTime());
    this.clickOnActiveMode();
  }

  companyAssetChange(companyAssets) {
    this.companyAssets = companyAssets;
  }

  updateAssets(assets) {
    this.assets = assets;
    if (this.router.url.includes('projects')) {
      let calEvents = [];
      assets.forEach(asset => {
        if (asset.inspection) {
          const startDate = moment(asset.inspection.startDate);
          const endDate = moment(asset.inspection.endDate);
          const frequency = (asset.inspection.frequency);
          const frequencyNumber = asset.inspection.frequency_number;
          if (frequency == 'day') {
            const events = this.getAllDayEvents(asset, startDate, endDate, frequencyNumber)
            calEvents = calEvents.concat(events);
          }
          if (frequency == 'month') {
            const events = this.getAllMonthEvents(asset, startDate, endDate, frequencyNumber)
            calEvents = calEvents.concat(events);
          }
          if (frequency == 'week') {
            const events = this.getAllWeekEvents(asset, startDate, endDate, frequencyNumber)
            calEvents = calEvents.concat(events);
          }
          if (frequency == 'year') {
            const events = this.getAllYearEvents(asset, startDate, endDate, frequencyNumber)
            calEvents = calEvents.concat(events);
          }
        }
      });
      this.calendarOptions.events = calEvents;
      this.clickOnActiveMode();
      this.cd.detectChanges();
    }
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  getAllDayEvents(asset, start, end, interval) {
    let events = [];
    for (var d = start; d <= end; d = moment(d).add(interval, 'days')) {
      events.push({
        title: asset.assetName,
        color: asset.inspection.color,
        allDay: true,
        date: new Date(d),
        id: asset.id
      })
    }
    return events;
  }

  getAllMonthEvents(asset, start, end, interval) {
    let events = [];
    for (var d = start; d <= end; d = moment(d).add(interval, 'month')) {
      events.push({
        title: asset.assetName,
        color: asset.inspection.color,
        allDay: true,
        date: new Date(d),
        id: asset.id
      })
    }
    return events;
  }

  getAllYearEvents(asset, start, end, interval) {
    let events = [];
    for (var d = start; d <= end; d = moment(d).add(interval, 'year')) {
      events.push({
        title: asset.assetName,
        color: asset.inspection.color,
        allDay: true,
        date: new Date(d),
        id: asset.id
      })
    }
    return events;
  }

  getAllWeekEvents(asset, start, end, interval) {
    let events = [];
    for (var d = start; d <= end; d = moment(d).add(7 * interval, 'days')) {
      events.push({
        title: asset.assetName,
        color: asset.inspection.color,
        allDay: true,
        date: new Date(d),
        id: asset.id
      })
    }
    return events;
  }

  allProjects(projets) {
    if (projets.length != 0 && !this.firstLoad) {
      this.assetSidePanelView = this.backendService.currentUserData?.dashPanel || 'map';
      this.firstLoad = true;
    }
    this.isProjectsLoading$.next(false);
    this.projects = projets;
  }

  projectTitle(title) {
    this.title = title
  }

  mapInit() {
    this.loadMap();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  isOwnerOfProject(project): boolean {
    return project.people[this.backendService.getCurrentUser().uid] &&
      project.people[this.backendService.getCurrentUser().uid].role === 'owner';
  }

  getRoleOfProject(project): string {
    const currentUser = project.people[this.backendService.getCurrentUser().uid];
    return currentUser ? currentUser.role : null;
  }

  handleAdd(): void {
    const assetId = this.getAssetId();
    const asset = this.assets.find(asset => asset.id == assetId);
    let companyId;
    if (this.uiService.selectedSideTab === 'assets') {
      companyId = this.uiService.selectedAssetNode.parent.id;
    }
    if (this.uiService.selectedSideTab === 'campaigns') {
      companyId = this.uiService.campaignAssetView ?
        this.uiService.selectedCampaignNode.company.companyId : this.uiService.selectedCampaignCompany.company.companyId
    }
    if (asset.domain && (asset.domain != this.backendService.DOMAIN_CONTROL?.id)) {
      this.toast.error(`you don't have access to add new projects for this asset.`);
      return;
    }
    const isValid = this.backendService.domainControlValid('projects');
    if (isValid) {
      this.dialog.open(ProjectsAddDialogComponent, {
        width: '510px',
        data: {
          projects: this.projects.map(project => project.name),
          assetId: asset.id,
          companyId: companyId,
          asset: asset
        }
      });
    } else {
      if (this.backendService.DOMAIN_CONTROL) {
        this.toast.error(`Project limit (${this.backendService.DOMAIN_CONTROL.projects}) reached. For more projects please reach out to sales@oceansai.tech.`);
      } else {
        this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
      }
    }
  }

  viewAssetInfo() {
    const assetId = this.getAssetId();
    const data = JSON.stringify({
      'assets': this.assets,
      'companyAssets': this.companyAssets,
      'id': assetId,
      'projects': this.projects || []
    })
    if (assetId) {
      this.router.navigate(["dashboard/assets"], {
        state: {
          data: data
        }
      })
    }
  }

  handleAddAsset() {
    const isValid = this.backendService.domainControlValid('assets');
    const data = JSON.stringify({
      'assets': this.assets,
      'companyAssets': this.companyAssets,
      'id': null,
      'projects': this.projects || []
    })
    if (isValid) {
      this.router.navigate(["dashboard/assets"], {
        state: {
          data: data
        }
      })
    } else {
      if (this.backendService.DOMAIN_CONTROL) {
        this.toast.error(`Assets limit (${this.backendService.DOMAIN_CONTROL.assets}) reached. For more assets please reach out to sales@oceansai.tech.`);
      } else {
        this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
      }
    }
  }

  handleDelete(project: Project): void {
    this.confirmDialog
      .open(
        `Are you sure you want to delete project "${project.name}"?`,
        {
          trueButtonTitle: 'Delete',
          falseButtonTitle: 'Cancel'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.backendService.markProjectDeleted(project.id);
        }
      });
  }

  // functions for delete modal:
  openDeleteDialog(project): void {
    this.deleteDialogRef = this.dialog.open(this.deleteDialog, {
      data: { project: project }
    });
    this.deleteDialogSubcription = this.deleteDialogRef.afterClosed().subscribe(result => {
      this.projectNameInput = '';
    });
  }

  closeDeleteDialog(): void {
    this.projectNameInput = '';
    this.deleteDialogRef.close();
  }

  deleteProject(project): void {
    this.backendService.markProjectDeleted(project.id);
    this.closeDeleteDialog();
  }

  // functions for rename modal:
  openRenameDialog(project): void {
    this.renameDialogRef = this.dialog.open(this.renameDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: { project: project }
    });
    this.projectNameInput = project.name;

    this.renameDialogSubcription = this.renameDialogRef.afterClosed().subscribe(result => {
      this.projectNameInput = '';
    });
  }

  // tslint:disable-next-line: member-ordering
  renameError = '';
  renameProject(id: string, name: string): void {
    this.renameError = '';
    this.backendService.updateProjectName(id, name).then(() => {
      this.projectNameInput = '';
      this.renameDialogRef.close();
    }).catch((error) => {
      this.renameError = 'Oops, something went wrong!';
    });
  }

  getReadOnlyForCurrentUser(project: IProject): boolean {
    return this.generalService.getReadOnlyForCurrentUser(project);
  }
  public title: string;


  handleCompare(assetId) {
    this.router.navigateByUrl(`/dashboard/asset/${assetId}`);
  }

  validateAsset() {
    if (this.filteredProjects.length >= 2) {
      return true;
    }
    return false;
  }

  getToolTip() {
    if (!this.validateAsset()) {
      return 'Must have 2 or more inspections of asset for Time-series view';
    } else {
      return 'Timeline View'
    }
  }

  getAssetId() {
    if (this.uiService.selectedSideTab === 'assets') {
      if (this.uiService.selectedAssetNode?.child?.id) {
        return this.uiService.selectedAssetNode?.child?.id;
      }
    }
    if (this.uiService.selectedSideTab === 'campaigns') {
      if (this.uiService.campaignAssetView) {
        return this.uiService.selectedCampaignNode?.asset;
      } else {
        return this.uiService.selectedCampaignCompany?.asset;

      }
    }
  }

  onValChange(value) {
    this.assetSidePanelView = value;
    if (value == 'map') {
      if (this.assets.length > 0) {
        this.loadMap();
      }
    } if (value == 'timeline') {
      const assetId = this.getAssetId();
      this.handleCompare(assetId)
    }
  }

  projectPanelValChange(value) {
    this.projectSidePanelView = value;
    if (value == 'inspection') {
      this.clickOnActiveMode();
    }
  }

  toggle() {
    if (this.projectSidePanelView == 'inspection') {
      this.clickOnActiveMode();
    }
  }

  clickOnActiveMode() {
    setTimeout(() => {
      const el: any = document.getElementsByClassName('fc-button-active')[0];
      if (el) { el.click(); }
    }, 200);
  }

  loadMap() {
    let locations = [];
    this.assets.forEach(element => {
      let isSelectedAsset: boolean = false;
      if (element.latitude && element.longitude) {
        const assetId = this.getAssetId();
        if (assetId === element.id) {
          isSelectedAsset = true;
        }
        locations.push({
          latitude: element.latitude,
          longitude: element.longitude,
          isSelectedAsset: isSelectedAsset,
          asset: element
        })
      }
    });
    if (locations.length) {
      this.assetMapData = true;
      let latLng = new google.maps.LatLng(locations[0].latitude, locations[0].longitude);
      let mapOptions = {
        center: latLng,
        zoom: 10,
        minZoom: 2,
        streetViewControl: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      if (this.markers.length) {
        this.markers.forEach(marker => {
          marker.setMap(null);
        });
        this.markers = [];
      }
      setTimeout(() => {
        if (!this.map) {
          this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
        }
        locations.forEach(location => {
          const latlng = new google.maps.LatLng(location.latitude, location.longitude)
          this.addMarker(latlng, this.map, location.asset, location.isSelectedAsset)
        });
        this.fitBoundsToVisibleMarkers();
      }, 200);

    } else {
      this.assetMapData = false;
    }
  }

  addMarker(position, map, asset, isAssetSelected) {
    let svg;
    if (isAssetSelected) {
      svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g>
       <path fill="#46b3e6" d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
     </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
    <ellipse stroke="#000" ry="95.000009" rx="96.5" id="svg_6" cy="180" cx="257.000005" stroke-width="0" fill="#000000"></ellipse>
    <text stroke="#000" transform="matrix(3.862098151115896,0,0,4.251692923930769,-679.3616247622514,-547.7456601798929) " space="preserve" text-anchor="middle" font-family="Helvetica, Arial, sans-serif" font-size="42" id="svg_7" y="187" x="240" stroke-opacity="null" stroke-width="0" fill="#ffffff">D</text>
    </g></svg>`;
    } else {
      svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g>
       <path fill="#476282" d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
     </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
    </g></svg>`;
    }

    const icon = {
      url: "data:image/svg+xml," + encodeURIComponent(svg),
      scaledSize: isAssetSelected ? new google.maps.Size(35, 45) : new google.maps.Size(30, 30), // scaled size
    };
    const marker = new google.maps.Marker({
      position,
      map,
      icon: icon,
    });

    const latestInspection = this.uiService.allProjects.filter(o => o.assetId === asset.id).sort((a, b) => new Date(b?.inspectionDate).getTime() - new Date(a?.inspectionDate).getTime());

    this.markers.push(marker);
    const contentString =
      `<h3>${asset.assetName}</h3>`;
    let infowindow = new google.maps.InfoWindow(
      {
        content: contentString
      }
    );
    marker.addListener('mouseover', function () {
      infowindow.open(map, this);

      setTimeout(() => {
        document.getElementsByClassName('gm-ui-hover-effect')[0]?.remove();
      }, 10);
    });
    marker.addListener('mouseout', function () {
      infowindow.close();
    });
    const me = this;
    marker.addListener('click', function () {
      if (!isAssetSelected && me.uiService.selectedSideTab != 'campaign') {
        me.refreshPanel(asset);
      }

    });
    marker.addListener('dblclick', function () {
      me.assetSidePanelView = 'panel';
    });


    if (latestInspection.length) {
      const latest = latestInspection[0];
      this.backendService.getSummary(latest.id).subscribe((result: any) => {
        if (result) {
          const levels = [];
          if (result.severityFeatures) {
            result.severityFeatures.forEach(severity => {
              const key: any = Object.keys(severity);
              levels.push({
                action: severity[key]?.action,
                title: severity[key]?.title,
                color: severity[key]?.color,
                highest_level: severity[key]?.highest_level || 0,
                tag: key,
                total: severity[key]?.total
              })
            });
          }
          const findings=[];
          if (result.features) {
            const severityTags = levels.map(o => o.tag[0]);
            const features = Object.keys(result.features);
            features.forEach(feature => {
              if (!severityTags.includes(feature)) {
                findings.push({
                  tag:feature,
                  total: result.features[feature]
                })
              }
            });
          }
          const max = Math.max(...levels.map(o => o.highest_level))
          if (max) {
            const color = levels?.find(o => o.highest_level == max)?.color;

            if (isAssetSelected) {
              svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g>
               <path fill="${color}" d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
             </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
            </g>
            <ellipse stroke="#000" ry="95.000009" rx="96.5" id="svg_6" cy="180" cx="257.000005" stroke-width="0" fill="#000000"></ellipse>
            <text stroke="#000" transform="matrix(3.862098151115896,0,0,4.251692923930769,-679.3616247622514,-547.7456601798929) " space="preserve" text-anchor="middle" font-family="Helvetica, Arial, sans-serif" font-size="42" id="svg_7" y="187" x="240" stroke-opacity="null" stroke-width="0" fill="#ffffff">D</text>
            </svg>
            `;
            } else {
              svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  
              viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g>
               <path fill="${color}" d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"/>
             </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g>
            </g>
            </svg>
            `;
            }
            const icon = {
              url: "data:image/svg+xml," + encodeURIComponent(svg),
              scaledSize: isAssetSelected ? new google.maps.Size(35, 45) : new google.maps.Size(30, 30)
            };
            marker.setIcon(icon);
            let str = ""
            levels.forEach(e => {
              str = str + `<tr >
            <td class='pr-10'>${e.tag[0]}</td>
            <td class='pr-10'> <span class='mapMarkerSeverity' style="background: ${e.color};
           "></span> ${e.title}</td>
            <td>${e.total}</td>
              </tr>`
            })
            findings.forEach(finding => {
              str = str + `<tr >
            <td class='pr-10'>${finding.tag}</td>
            <td class='pr-10'>N/A</td>
            <td>${finding.total}</td>
              </tr>`
            });
            let content = `<div>
            <p> <strong> ${asset.assetName} (${latest.name})</strong></p>
           <table class='table'>
            <thead>
            <tr>
            <th >Findings</th>
             <th >Severity</th>
             <th >Total Annotations</th>
           </thead>  </tr> <tbody>` + str + `</tbody>
           </table>
           </div>`
            infowindow = new google.maps.InfoWindow(
              {
                content: content
              }
            );
          }
        }
      })
    }

  }

  refreshPanel(asset) {
    let nodeIndex = this.uiService.assetsNodes.findIndex(o => o.id == asset.companyId);
    if (nodeIndex != -1) {
      let childIndex = this.uiService.assetsNodes[nodeIndex].children.findIndex(o => o.id == asset.id);
      this.uiService.mapSelectedEvent$.next({
        parent: this.uiService.assetsNodes[nodeIndex],
        child: this.uiService.assetsNodes[nodeIndex].children[childIndex]
      });
      setTimeout(() => {
        this.loadMap();
      }, 50);
    }

  }

  fitBoundsToVisibleMarkers() {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < this.markers.length; i++) {
      if (this.markers[i].getVisible()) {
        bounds.extend(this.markers[i].getPosition());
      }
    }
    this.map.fitBounds(bounds);
  }

  isSearch: boolean = false;
  searchToggle() {
    this.isSearch = !this.isSearch;
  }

  handleAddCampaign() {
    this.dialog.open(AddCampaignDialogeComponent, {
      width: '510px',
      data: {}
    });

  }
}
