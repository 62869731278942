<sub-headings class="sub-headings mx-auto py-2 w-100 border-bottom container-fluid">
    <div class="row">
        <div class="col-12 d-flex align-items-center">
            <div class="title py-0 pr-3 pl-0">2D View For {{asset?.companyName}} - {{asset?.assetName}} </div>
            <div class="ml-auto d-flex side-right justify-content-end align-items-center">
                <button *ngIf="type ==='timeline'" class="mr-1" [disabled]="!getSelectedLabels()"
                    (click)="filterImages()" mat-raised-button>
                    Filter
                </button>
                <div class="d-flex align-items-center"
                    *ngIf="backendService.DOMAIN_CONTROL?.features?.qrCodeGeneration">
                    <div class="d-flex" *ngIf="drawing">
                        <button *ngIf="annotations.length" (click)="exportQR()"
                            [disabled]="drawing.status=='processing' || !isAssetOwner" mat-raised-button
                            class="mr-2 ml-2 dark export">
                            <span class="material-icons">qr_code</span>
                            <span class="text-uppercase">{{!drawing.zipfileUrl ? 'Export QR Locations' : 'Re-Export QR
                                Locations'
                                }}</span>
                            <mat-spinner [diameter]="20" class="loading" *ngIf="drawing.status === 'processing'">
                            </mat-spinner>
                        </button>
                        <button
                            [disabled]="!annotations.length || drawing.status === 'processing' || !drawing.zipfileUrl"
                            mat-raised-button class="download">
                            <span class='material-icons-outlined'>file_download</span>
                            <a [class.disabled]="!annotations.length || drawing.status === 'processing' || !drawing.zipfileUrl"
                                href="{{drawing.zipfileUrl}}" target="blank">
                                Download QR
                            </a>
                        </button>
                        <button *ngIf="baseFile && type !='report'"
                            matTooltip="{{is2DPanelExpand ? 'collapse' : 'expand'}}" (click)="toggle2DPanelView()"
                            class="toggle-icon no-border-button">
                            <mat-icon>
                                <span *ngIf="!is2DPanelExpand" class="material-icons">view_sidebar
                                </span>
                                <span *ngIf="is2DPanelExpand" class="material-icons">view_array
                                </span>
                            </mat-icon>
                        </button>
                        <button class="no-border-button">
                            <mat-icon class="help-tip mr-2 ml-1" [matTooltip]="'
    Mouse + scroll: ZoomIn and ZoomOut of image area
    Mouse +  left click: To create a new location label
    '">help</mat-icon>
                        </button>
                    </div>

                </div>


                <!-- ------------DXf headers----- -->
                <div *ngIf="dxfFile && viewer" class="d-flex align-items-center">
                    <button style=" background: transparent;" *ngIf="type === 'asset'" class='material-icons-outlined'
                        mat-raised-button [disabled]="!isAssetOwner || dxfLoading" (click)="deletePrompt()">
                        <mat-icon matTooltip="delete model">delete_outline</mat-icon>
                    </button>
                    <mat-button-toggle-group [disabled]="!isAssetOwner || !isPanelDXF || dxfLoading ||  type ==='report' ||  type ==='project'
                        || type === 'timeline'" [(ngModel)]="viewer.toolBox">
                        <mat-button-toggle (dblclick)="onDoubleTap('L1')" class="toolbox" matTooltip='{{levels.L1}}'
                            [class.btn_active]="viewer.toolBox === 'L1'" (change)="onValChange($event.value)"
                            value="L1">
                            {{levels.L1}}
                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L2')" class="toolbox" matTooltip='{{levels.L2}}'
                            [class.btn_active]="viewer.toolBox === 'L2'" (change)="onValChange($event.value)"
                            value="L2">
                            {{levels.L2}}

                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L3')" class="toolbox" matTooltip='{{levels.L3}}'
                            [class.btn_active]="viewer.toolBox === 'L3'" (change)="onValChange($event.value)"
                            value="L3">
                            {{levels.L3}}

                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L4')" class="toolbox" matTooltip='{{levels.L4}}'
                            [class.btn_active]="viewer.toolBox === 'L4'" (change)="onValChange($event.value)"
                            value="L4">
                            {{levels.L4}}

                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L5')" class="toolbox" matTooltip='{{levels.L5}}'
                            [class.btn_active]="viewer.toolBox === 'L5'" (change)="onValChange($event.value)"
                            value="L5">
                            {{levels.L5}}
                        </mat-button-toggle>


                    </mat-button-toggle-group>

                    <button *ngIf="baseFile && isPanelDXF && type !='report'"
                        matTooltip="{{is2DPanelExpand ? 'collapse' : 'expand'}}" (click)="toggleDXFPanelView()"
                        class="toggle-icon no-border-button">
                        <mat-icon>
                            <span *ngIf="!is2DPanelExpand" class="material-icons">view_sidebar
                            </span>
                            <span *ngIf="is2DPanelExpand" class="material-icons">view_array
                            </span>
                        </mat-icon>
                    </button>

                </div>


                <!-- ------------KML headers----- -->
                <mat-button-toggle-group class="mr-1"
                    *ngIf="type ==='project' &&  ((drawing?.isKMLFile) ||  (kmlFile && kmlFile.shpStatus === success))"
                    [(ngModel)]="toolBox">
                    <mat-button-toggle class="toolbox"
                        matTooltip='select the  area on model (mouse + right click) to display linked images'
                        [class.btn_active]="toolBox === 'rectangle'" (change)="onKMZValChange($event.value)"
                        value="rectangle">
                        <span class="material-icons">
                            rectangle
                        </span>
                    </mat-button-toggle>

                </mat-button-toggle-group>


                <div *ngIf="kmlFile" class="d-flex align-items-center">
                    <button style=" background: transparent;" *ngIf="type === 'asset'" class='material-icons-outlined'
                        mat-raised-button [disabled]="!isAssetOwner || dxfLoading" (click)="deletePrompt()">
                        <mat-icon matTooltip="delete model">delete_outline</mat-icon>
                    </button>

                    <mat-button-toggle-group
                    *ngIf="kmlFile.shpStatus === success"
                        [disabled]="!isAssetOwner  ||  type ==='report' || type ==='project' || type ==='timeline' "
                        [(ngModel)]="toolBox">
                        <mat-button-toggle (dblclick)="onDoubleTap('L1')" class="toolbox" matTooltip='{{levels.L1}}'
                            [class.btn_active]="toolBox === 'L1'" (change)="onKMZValChange($event.value)" value="L1">
                            {{levels.L1}}
                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L2')" class="toolbox" matTooltip='{{levels.L2}}'
                            [class.btn_active]="toolBox === 'L2'" (change)="onKMZValChange($event.value)" value="L2">
                            {{levels.L2}}

                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L3')" class="toolbox" matTooltip='{{levels.L3}}'
                            [class.btn_active]="toolBox === 'L3'" (change)="onKMZValChange($event.value)" value="L3">
                            {{levels.L3}}

                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L4')" class="toolbox" matTooltip='{{levels.L4}}'
                            [class.btn_active]="toolBox === 'L4'" (change)="onKMZValChange($event.value)" value="L4">
                            {{levels.L4}}

                        </mat-button-toggle>
                        <mat-button-toggle (dblclick)="onDoubleTap('L5')" class="toolbox" matTooltip='{{levels.L5}}'
                            [class.btn_active]="toolBox === 'L5'" (change)="onKMZValChange($event.value)" value="L5">
                            {{levels.L5}}
                        </mat-button-toggle>


                    </mat-button-toggle-group>

                    <button *ngIf="baseFile && type !='report'" matTooltip="{{is2DPanelExpand ? 'collapse' : 'expand'}}"
                        (click)="toggleKmlPanelView()" class="toggle-icon no-border-button">
                        <mat-icon>
                            <span *ngIf="!is2DPanelExpand" class="material-icons">view_sidebar
                            </span>
                            <span *ngIf="is2DPanelExpand" class="material-icons">view_array
                            </span>
                        </mat-icon>
                    </button>

                </div>

                <button (click)="dialogRef.close();"
                    *ngIf="type == 'report' ||  type == 'project'  || type == 'timeline'" class="no-border-button">
                    <mat-icon> close</mat-icon>
                </button>



            </div>
        </div>
    </div>
</sub-headings>



<!-- ---2D image Content--- -->
<div [hidden]="!baseFile || dxfFile || kmlFile" class="row p-0">

    <div class="col" style="width: 50vh;">

        <div (click)="toggleLeftMenu()" [style.left]="!isLeftMenu ? type ==='timeline'?  '240px'  : '210px' : '5px'"
            *ngIf="annotations.length > 0" class="left-menu">
            <mat-icon>{{!isLeftMenu ? 'arrow_left' : 'arrow_right'}}</mat-icon>
        </div>
        <div [class]="type ==='asset' ? 'asset' : 'project'" *ngIf="annotations.length > 0 && !isLeftMenu"
            id="annotationsPanel">
            <ul class="ul-list d-flex" *ngFor="let annotation of annotations;let i = index">
                <input *ngIf="type === 'timeline'" type="checkbox" class="left-checkbox"
                    [(ngModel)]="annotation.checked" />

                <button [disabled]="type !='project'" [class.active]="selectedLabel?.id === annotation.id" (click)="gotoAnnotation(annotation)"
                    class="annotationButton d-flex">
                    <p class="circle small" [style.background]="annotation.color"></p> {{annotation?.title}}
                </button>
                <button *ngIf="type ==='asset'" mat-icon-button [disabled]="!isAssetOwner" class="delete"
                    (click)="removeLabelPrompt(annotation)">
                    <mat-icon matTooltip="delete location label">delete_outline</mat-icon>
                </button>
                <button *ngIf="type === 'project'" mat-icon-button  class="delete" [disabled]="getReadOnlyForCurrentUser()" (click)="linkMedias(annotation)">
                    <mat-icon matTooltip="link medias">add_circle_outline</mat-icon>
                  </button>
            </ul>
        </div>


        <div *ngIf="!drawing?.isKMLFile" class="m-2 grid-y h-100 w-100 container-box"
            [class]="type ==='asset' ? 'asset' : 'project'" #containerImage>

            <ko-stage #stage [config$]="configStage$" (mousewheel)="handleZoom($event)" (onclick)="handleClick($event)"
                (wheel)="handleZoom($event)" (touchmove)="handleZoom($event)" (ondragmove)="handleZoom($event)">
                <ko-layer>
                    <ko-image [config$]="configImg$"></ko-image>
                </ko-layer>
                <ko-layer>
                    <ng-container *ngFor="let area of activeImgAreas; let i = index">
                        <ko-circle (onmouseover)="hoverChildContextImages(area, true);"
                            (onmouseout)="hoverChildContextImages(area, false);" [config$]="area.config$">
                        </ko-circle>
                        <ko-text *ngIf="area.isShow" [config$]="area.configText$"></ko-text>
                    </ng-container>
                </ko-layer>
            </ko-stage>
        </div>

        <!-- Basic KMZ File -->
        <div [hidden]="!drawing?.isKMLFile ||  drawing?.isShapeFile" [class]="type ==='asset' ? 'asset-map' : 'project'"
            id="map">

        </div>

    </div>

    <div *ngIf="!is2DPanelExpand && type !='report'" class="col bg">

        <div class="text-center justify-content-center w-100 align-items-center mt-5" *ngIf="rcImages.loading">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            Please wait... fetching images
        </div>
        <div [class]="type ==='asset' ? 'asset' : 'project'" class="col scroll-img" *ngIf="rcImages.images.length">
            <p class="col-sm-4 node" *ngFor="let child of rcImages.images">
                <img (click)="openModal(child)" class="link-img" [src]="child.thumbFileUrl" />
                <span class="file-name wrap-text">{{ child.fileName }}</span>
            </p>
        </div>

        <div id="capture"> </div>
        <div *ngFor="let node of annotations;let i= index">
            <div class="scroll-img" [class]="type ==='asset' ? 'asset' : 'project'"
                style="font-weight: 700; padding-top: 10px;" *ngIf="selectedLabel?.id === node.id">
                <p class="circle">{{i + 1}}</p>
                <span>
                    {{node.title}}
                </span>
                <div *ngIf="linkImages">
                    <p class="col-sm-4 node" *ngFor="let child of linkImages[node.id]">
                        <img (click)="openModal(child,node)" class="link-img"
                            [src]="child.type=='videos'? child.posterImage : child.thumbFileUrl" />
                        <mat-icon (click)="openModal(child,node)" *ngIf="child.type=='videos'" class="vid-icon">
                            video_library</mat-icon>
                        <mat-icon class="info" matTooltip="log info"
                            *ngIf="child.type !='videos' && uiService.project?.inspectionType === FORSSEA"
                            (click)="logInfo(child)">code</mat-icon>
                        <!-- <mat-icon class="edit" *ngIf="child.type !='videos'" (click)="preview(child)">edit</mat-icon> -->
                        <button class="unlink" mat-icon-button [disabled]="!isAssetOwner" (click)="remove(child,node)">
                            <mat-icon matTooltip="unlink" *ngIf="!child.loading">delete</mat-icon>
                            <mat-spinner [diameter]="20" *ngIf="child.loading"></mat-spinner>
                        </button>
                        <span class="file-name wrap-text">{{ child.fileName }}</span>
                    </p>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="baseFile &&  type ==='report'" class="col bg">
        <button (click)="addToReport()" [disabled]="isChecked()" mat-raised-button color="primary"
            class="mb-0 mt-2 mr-1">
            <span class="text-uppercase">Add</span>
        </button>

        <div style="font-weight: 700; padding-top: 10px;">
            <div class="col-sm-4 node" *ngFor="let image of technicalDrawings;let idx =index">
                <div class="h-100 w-100">
                    <img [src]="image.fileUrl" />
                    <div class="carousel-filename">{{ image.fileName }}</div>
                    <input type="checkbox" class="checkbox-grid" [(ngModel)]="image.checked" />
                </div>
            </div>
        </div>
    </div>

    <!-- ------Right Menu to change technical drawing------- -->
    <div (click)="toggleRightMenu()" [style.right]="!isRightMenu ? '5px' : '150px'"
        *ngIf="technicalDrawings.length > 0 && !drawing?.isKMLFile" class="right-menu">
        <mat-icon>{{!isRightMenu ? 'arrow_left' : 'arrow_right'}}</mat-icon>
    </div>
    <div *ngIf="technicalDrawings.length > 0 && isRightMenu" class="top-caruasal">
        <button class="carusal-div" [disabled]="!isAssetOwner  || isImageUploading$.value" (click)="handleAdd()"
            style="border:none">
            <span class="material-icons" style="font-size: 3rem;display: flow-root;">file_upload</span>
            UPLOAD
            <span *ngIf="isImageUploading$ | async">
                <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
            </span>
        </button>
        <ng-container>
            <div class="carusal-div" *ngFor="let image of technicalDrawings; let i = index">
                <img (click)="carausalActivateImage(image)" [src]="image.fileUrl"
                    [class.active]="image.id == drawing?.id" />
                <div class="carousel-filename">{{ image.fileName }}</div>
                <button style="position: relative; bottom: 120px; right: 5px;" mat-icon-button
                    [disabled]="!isAssetOwner" (click)="removeDrawing(image)">
                    <mat-icon style="color:red">delete</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
    <!-- ------------ -->

</div>


<!-- ---DXF Content--- -->
<div [hidden]="!dxfFile" class="row p-0">
    <div class="col" style="width: 50vh;">
        <div id="dxfPanel"></div>
    </div>

    <div *ngIf="!is2DPanelExpand" class="col bg">

        <div *ngIf="type ==='report'" class="col scroll-img">
            <button [disabled]="isImageUploading$.value" (click)="captureNew()" mat-raised-button
                class="mb-0 mt-2 mr-1 dark">
                <span class="text-uppercase">Capture New screens</span>
                <span style="display:inline-block" *ngIf="isImageUploading$ | async">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </span>
            </button>

            <button (click)="addDXFToReport()" [disabled]="isDXFFileChecked()" color="primary" mat-raised-button
                class="mb-0 mt-2 mr-1">
                <span class="text-uppercase">Add</span>
            </button>

            <div style="font-weight: 700; padding-top: 10px;">
                <div style="height: auto;" class="col-sm-4 node"
                    *ngFor="let screen of this.dxfFile?.screens;let idx =index">
                    <div>
                        <img [src]="screen.fileUrl" />
                        <input type="checkbox" class="checkbox-grid" [(ngModel)]="screen.checked" />
                        <mat-icon class="screen-delete" (click)="deleteScreen(idx)">delete</mat-icon>
                        <mat-icon [ngStyle]="{ color: screen.title ?'#4456F9' : '#FFFFFF' }" (click)="edit(screen)"
                            class="note-icon" *ngIf="!screen.editing">note</mat-icon>
                        <span class="edit-box" *ngIf="screen.editing">
                            <input [(ngModel)]="screen.title" placeholder='title' type="text" ng-model="user.last">
                            <mat-icon [disabled]="!screen.title" (click)="update()">check</mat-icon>
                        </span>

                    </div>
                </div>
            </div>
        </div>

        <div class="text-center justify-content-center w-100 align-items-center mt-5"
            *ngIf="dxfLoading || this.viewer?.scene?.children?.length < 2">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            Please wait... your file content is loading
        </div>

        <div class="text-center justify-content-center w-100 align-items-center mt-5"
            *ngIf="(!dxfLoading && dxfFile?.dxfStatus === success && !isPanelDXF) || (!dxfLoading && isPanelDXF && ( this.viewer?.scene?.children?.length >= 2 && this.viewer?.scene?.children?.length < 3))">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            Please wait... We are loading your panel layers.
        </div>

        <div *ngIf="(!dxfLoading  && !isPanelDXF && (dxfFile?.dxfStatus === queued || 
        dxfFile?.dxfStatus === processing)
        ||
        !dxfFile?.dxfStatus && dxfFile?.panelLayers
        )" class="text-center justify-content-center w-100 align-items-center mt-5">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            <b>
                Please wait... We are extracting panel layers of your DXF File
                <br>
                Once its completed you will be able to create location labels.
            </b>
            <br>
            <span>
                process will take 1-2 mins. to complete
            </span>
        </div>

        <div *ngIf="!dxfLoading  && isPanelDXF && this.viewer?.scene?.children?.length >= 3 && !nodes.length && (dxfFile?.dxfStatus === success)"
            class="text-center justify-content-center w-100 align-items-center mt-5">
            <b>
                Select the Area on DWG ({{this.levels.L1}} - {{this.levels.L4}}) for solar panel drawing. {{levels.L5}}
                will automatically created.
                <br>
                (Mouse + left key selection)

            </b>
        </div>
        <div *ngIf="!dxfLoading  && !isPanelDXF && dxfFile?.dxfStatus === failed" style="color:#e72222"
            class="text-center justify-content-center w-100 align-items-center mt-5">
            <b>
                Your panel layers is failed to extract,please contact us with your dxf model.
            </b>
        </div>

        <div class="m-2 p-2 row" *ngIf="leftImage || rightImage">
            <div class="col" style="border: 2px solid #FFA500;" *ngIf="leftImage">
                <div *ngIf="checkYaw(leftImage);" (click)="rotateImage(leftImage)" class="d-flex north-indicator">
                    <span [style.color]="leftImage?.isRotate ? 'green' :'red'">
                        N
                    </span>
                    <img src="assets/arrow.svg" [style.transform]="'rotate(' + calculateRotation(leftImage) + 'deg)'" />
                </div>
                <img [style.transform]="'rotate(' + calculateImageRotation(leftImage) + 'deg)'"
                    [src]="leftImage.colorMediumFileUrl ||  leftImage.mediumFileUrl" />

                <div class="d-flex">
                    <mat-icon matTooltip="highlight/unhighlight panels" class="info-toggle"
                        (click)="markedImage(leftImage.id)"
                        [style.color]="highlightedImageId &&  highlightedImageId ===leftImage.id?'#FFA500':'#222'"
                        *ngIf="markingPanels.length ===2">info</mat-icon>
                    {{ leftImage.fileName }}
                </div>
            </div>
            <div style="border: 2px solid #0000FF;" class="col" *ngIf="rightImage">
                <div *ngIf="checkYaw(rightImage);" (click)="rotateImage(rightImage)" class="d-flex north-indicator">
                    <span [style.color]="rightImage?.isRotate ? 'green' :'red'">
                        N
                    </span>
                    <img src="assets/arrow.svg"
                        [style.transform]="'rotate(' + calculateRotation(rightImage) + 'deg)'" />
                </div>
                <img [style.transform]="'rotate(' + calculateImageRotation(rightImage) + 'deg)'"
                    [src]="rightImage.colorMediumFileUrl ||  rightImage.mediumFileUrl" />

                <div>
                    <mat-icon matTooltip="highlight/unhighlight panels" class="info-toggle"
                        (click)="markedImage(rightImage.id)"
                        [style.color]="highlightedImageId &&  highlightedImageId ===rightImage.id?'#0000FF':'#222'"
                        *ngIf="markingPanels.length ===2">info</mat-icon>
                    {{ rightImage.fileName }}
                </div>
            </div>

        </div>
        <div *ngIf="type !='report' && selectedLabel" [class]="type ==='asset' ? 'asset' : 'project'"
            class="scroll-img">
            <div style="font-weight: 700; padding-top: 10px;">
                <span>
                    {{selectedLabel?.name}}
                </span>
                <div *ngIf="linkImages && dxfFile">
                    <div class="col-sm-4 node" *ngFor="let child of linkImages">
                        <img *ngIf="child.type== 'videos'" (click)="openModal(child,selectedLabel)" class="link-img"
                            [src]="child.posterImage" /> 
                            <app-bounding-box (click)="openModal(child,selectedLabel)" *ngIf="child.type === 'images'"
                            [rotateDeg]="calculateImageRotation(child,true)" [image]="child"></app-bounding-box>
                        <mat-icon (click)="openModal(child,selectedLabel)" *ngIf="child.type=='videos'"
                            class="vid-icon">
                            video_library</mat-icon>
                        <!-- <mat-icon class="edit" *ngIf="child.type !='videos'" (click)="preview(child)">edit</mat-icon> -->
                        <!-- <button class="unlink" mat-icon-button [disabled]="!isAssetOwner"
                            (click)="removeDXFLinkImages(child,selectedLabel)">
                            <mat-icon matTooltip="unlink" *ngIf="!child.loading">delete</mat-icon>
                            <mat-spinner [diameter]="20" *ngIf="child.loading"></mat-spinner>
                        </button> -->
                        <div>{{ child.fileName }}</div>

                    </div>
                </div>
            </div>

        </div>


    </div>

    <div (click)="toggleLeftMenu()" [style.left]="!isLeftMenu ? type ==='timeline'?  '275px'  : '275px' : '2px'"
        *ngIf="!panelsVisible && nodes.length && type !='report'" class="left-menu">
        <mat-icon>{{!isLeftMenu ? 'arrow_left' : 'arrow_right'}}</mat-icon>
    </div>
    <div [hidden]="isLeftMenu" *ngIf="!panelsVisible && nodes.length  && type !='report'"
        [class]="type ==='asset' ? 'asset' : 'project'" class="dxf-panel">
        <tree-root #dxfTreeComponent [[focused]="true" [nodes]="nodes" [options]="treeOptions" [state]="state">
            <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                <div class="node-wrapper" [style.padding-left]="node.getNodePadding()"
                    [class.node-active]="node.data.id === selectedPanelId">
                    <tree-node-expander [node]="node"></tree-node-expander>
                    <div class="node-content-wrapper w-100">
                        <div class="d-flex w-100">
                            <span (click)="handleNodeClick(node); $event.stopImmediatePropagation();"
                                class="w-100  wrap-text" matTooltip="{{node.data.name}}">
                                {{node.data.name}}
                            </span>
                            <input (ngModelChange)="nodeSelect(node,$event)"
                                *ngIf="type === 'timeline'  && !panelsVisible" type="checkbox" class="left-checkbox"
                                [(ngModel)]="node.data.checked" />
                            <mat-icon *ngIf="type ==='asset'" [disabled]="!isAssetOwner"
                                (click)="handleEditNodeClick(node); $event.stopImmediatePropagation();"
                                style="float:right;" id="edit" svgIcon="pencil_outline"></mat-icon>

                            <mat-icon *ngIf="type ==='asset'" [disabled]="!isAssetOwner"
                                (click)="handleDeleteNodeClick(node); $event.stopImmediatePropagation();"
                                style="float:right;color:red" id="delete" matTooltip="delete label">delete_outline
                            </mat-icon>

                        </div>

                    </div>
                </div>
            </ng-template>
        </tree-root>
    </div>


</div>


<!-- ---KML Content--- -->
<div [hidden]="!kmlFile" class="row p-0">
    <div class="col" style="width: 50vh;">
        <div [hidden]="kmlFile?.shpfile_conversion_status != success"
            [class]="type ==='asset' ? 'asset-map' : 'project'" id="kmlMap">
        </div>
        <ul *ngIf="!kmzProcessing && kmlFile?.shpStatus === success" class="d-flex other-layer">
            <input (change)=otherLayers($event.target); type="checkbox" [checked]="false" class="left-checkbox top-0">
            <span matTooltip="Other helpful layers"> Other layers </span>
        </ul>
        <ul style="top:35px;" *ngIf="!kmzProcessing && type =='asset' && kmlFile?.shpStatus === success && nodes.length"
            class="d-flex other-layer">
            <input (change)=showMarkedEntities($event.target.checked); [(ngModel)]="isLayerShow" type="checkbox"
                class="left-checkbox top-0">
            <span matTooltip="To show all marked entity"> Marked areas </span>
        </ul>
    </div>
    <div *ngIf="!is2DPanelExpand" class="col bg">

        <!-- <div *ngIf="type ==='report'" class="col scroll-img">
                <button [disabled]="isImageUploading$.value" (click)="captureNew()" mat-raised-button
                    class="mb-0 mt-2 mr-1 dark">
                    <span class="text-uppercase">Capture New screens</span>
                    <span style="display:inline-block" *ngIf="isImageUploading$ | async">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </span>
                </button>

                <button (click)="addDXFToReport()" [disabled]="isDXFFileChecked()" color="primary" mat-raised-button
                    class="mb-0 mt-2 mr-1">
                    <span class="text-uppercase">Add</span>
                </button>

                <div style="font-weight: 700; padding-top: 10px;">
                    <div style="height: auto;" class="col-sm-4 node"
                        *ngFor="let screen of this.dxfFile?.screens;let idx =index">
                        <div>
                            <img [src]="screen.fileUrl" />
                            <input type="checkbox" class="checkbox-grid" [(ngModel)]="screen.checked" />
                            <mat-icon class="screen-delete" (click)="deleteScreen(idx)">delete</mat-icon>
                            <mat-icon [ngStyle]="{ color: screen.title ?'#4456F9' : '#FFFFFF' }" (click)="edit(screen)"
                                class="note-icon" *ngIf="!screen.editing">note</mat-icon>
                            <span class="edit-box" *ngIf="screen.editing">
                                <input [(ngModel)]="screen.title" placeholder='title' type="text" ng-model="user.last">
                                <mat-icon [disabled]="!screen.title" (click)="update()">check</mat-icon>
                            </span>

                        </div>
                    </div>
                </div>
            </div> -->

        <div class="text-center justify-content-center w-100 align-items-center mt-5" *ngIf="kmzProcessing">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            Please wait... your file content is loading
        </div>


        <div *ngIf="(kmlFile?.panelLayers &&  !kmlFile?.shpStatus) || (kmlFile?.shpStatus === queued || 
            kmlFile?.shpStatus === processing
       )" class="text-center justify-content-center w-100 align-items-center mt-5">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            <b>
                Please wait... We are extracting panel layers of your Shape File
                <br>
                Once its completed you will be able to create location labels.
            </b>
            <br>
            <span>
                process will take 1-2 mins. to complete
            </span>
        </div>

        <div *ngIf="type ==='asset' && !nodes.length && kmlFile?.shpStatus === success"
            class="text-center justify-content-center w-100 align-items-center mt-5">
            <b>
                Select the Area on KML ({{this.levels.L1}} - {{this.levels.L4}}) for solar panel drawing.
                {{levels.L5}}
                will automatically created.
                <br>
                (Mouse + right key selection)

            </b>
        </div>
        <div *ngIf="kmlFile?.shpStatus === failed" style="color:#e72222"
            class="text-center justify-content-center w-100 align-items-center mt-5">
            <b>
                Your panel layers is failed to extract,please contact us with your shape file model.
            </b>
        </div>

        <div *ngIf="!kmlFile?.panelLayers && !kmlFile?.shpStatus">
            <div class="panel-layers-lists">
                <span class="m-1 p-1">
                    Select your panel layers
                </span>
                <ul class="ul-list d-flex" *ngFor="let layer of markedLayers;let i = index">
                    <input type="checkbox" (change)=choosePanelLayers(layer); class="left-checkbox top-0"
                        [(ngModel)]="layer.checked" />
                    <span>
                        {{layer.folderName}}
                    </span>
                </ul>
            </div>
            <button mat-raised-button color="primary" (click)="generatePanelKmz()">
                Select
            </button>
        </div>

        <div class="m-2 p-2 row" *ngIf="leftImage || rightImage">
            <div class="col" style="border: 2px solid #FFA500;" *ngIf="leftImage">
                <div *ngIf="checkYaw(leftImage);" (click)="rotateImage(leftImage)" class="d-flex north-indicator">
                    <span [style.color]="leftImage?.isRotate ? 'green' :'red'">
                        N
                    </span>
                    <img src="assets/arrow.svg" [style.transform]="'rotate(' + calculateRotation(leftImage) + 'deg)'" />
                </div>
                <img [style.transform]="'rotate(' + calculateImageRotation(leftImage) + 'deg)'"
                    [src]="leftImage.colorMediumFileUrl ||  leftImage.mediumFileUrl" />

                <div class="d-flex">
                    <mat-icon matTooltip="highlight/unhighlight panels" class="info-toggle"
                        (click)="markedImage(leftImage.id)"
                        [style.color]="highlightedImageId &&  highlightedImageId ===leftImage.id?'#FFA500':'#222'"
                        *ngIf="markingPanels.length ===2">info</mat-icon>
                    {{ leftImage.fileName }}
                </div>
            </div>
            <div style="border: 2px solid #0000FF;" class="col" *ngIf="rightImage">
                <div *ngIf="checkYaw(rightImage);" (click)="rotateImage(rightImage)" class="d-flex north-indicator">
                    <span [style.color]="rightImage?.isRotate ? 'green' :'red'">
                        N
                    </span>
                    <img src="assets/arrow.svg"
                        [style.transform]="'rotate(' + calculateRotation(rightImage) + 'deg)'" />
                </div>
                <img [style.transform]="'rotate(' + calculateImageRotation(rightImage) + 'deg)'"
                    [src]="rightImage.colorMediumFileUrl ||  rightImage.mediumFileUrl" />

                <div>
                    <mat-icon matTooltip="highlight/unhighlight panels" class="info-toggle"
                        (click)="markedImage(rightImage.id)"
                        [style.color]="highlightedImageId &&  highlightedImageId ===rightImage.id?'#0000FF':'#222'"
                        *ngIf="markingPanels.length ===2">info</mat-icon>
                    {{ rightImage.fileName }}
                </div>
            </div>

        </div>
        <div *ngIf="type !='report' && selectedLabel" [class]="type ==='asset' ? 'asset' : 'project'"
            class="scroll-img">
            <div style="font-weight: 700; padding-top: 10px;">
                <span>
                    {{selectedLabel?.name}}
                </span>
                <div *ngIf="linkImages && kmlFile">
                    <div class="col-sm-4 node" *ngFor="let child of linkImages">
                        <img *ngIf="child.type== 'videos'" (click)="openModal(child,selectedLabel)" class="link-img"
                            [src]="child.posterImage" />
                        <app-bounding-box *ngIf="child.type === 'images'" (click)="openModal(child,selectedLabel)"
                            [rotateDeg]="calculateImageRotation(child,true)" [image]="child"></app-bounding-box>

                        <mat-icon (click)="openModal(child,selectedLabel)" *ngIf="child.type=='videos'"
                            class="vid-icon">
                            video_library</mat-icon>
                        <!-- <mat-icon class="edit" *ngIf="child.type !='videos'" (click)="preview(child)">edit</mat-icon> -->
                        <!-- <button class="unlink" mat-icon-button [disabled]="!isAssetOwner"
                            (click)="removeDXFLinkImages(child,selectedLabel)">
                            <mat-icon matTooltip="unlink" *ngIf="!child.loading">delete</mat-icon>
                            <mat-spinner [diameter]="20" *ngIf="child.loading"></mat-spinner>
                        </button> -->
                        <div>{{ child.fileName }}</div>

                    </div>
                </div>
            </div>

        </div>

        <div class="text-center justify-content-center w-100 align-items-center mt-5" *ngIf="rcImages.loading">
            <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
            Please wait... fetching images
        </div>
        <div [class]="type ==='asset' ? 'asset' : 'project'" class="col scroll-img" *ngIf="rcImages.images.length">
            <p class="col-sm-4 node" *ngFor="let child of rcImages.images">
                <img (click)="openModal(child)" class="link-img" [src]="child.thumbFileUrl" />
                <span class="file-name wrap-text">{{ child.fileName }}</span>
            </p>
        </div>

    </div>

    <div (click)="toggleLeftMenu()" [style.left]="!isLeftMenu ? type ==='timeline'?  '275px'  : '275px' : '2px'"
        *ngIf="!panelsVisible && nodes.length && type !='report'" class="left-menu">
        <mat-icon>{{!isLeftMenu ? 'arrow_left' : 'arrow_right'}}</mat-icon>
    </div>
    <div [hidden]="isLeftMenu" *ngIf="!panelsVisible && nodes.length  && type !='report'"
        [class]="type ==='asset' ? 'asset' : 'project'" class="dxf-panel">
        <tree-root #dxfTreeComponent [[focused]="true" [nodes]="nodes" [options]="treeOptions" [state]="state">
            <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                <div class="node-wrapper" [style.padding-left]="node.getNodePadding()"
                    [class.node-active]="node.data.id === selectedPanelId">
                    <tree-node-expander [node]="node"></tree-node-expander>
                    <div class="node-content-wrapper w-100">
                        <div class="d-flex w-100">
                            <span (click)="handleNodeClick(node,true); $event.stopImmediatePropagation();"
                                class="w-100  wrap-text" matTooltip="{{node.data.name}}">
                                {{node.data.name}}
                            </span>
                            <input (ngModelChange)="nodeSelect(node,$event)"
                                *ngIf="type === 'timeline'  && !panelsVisible" type="checkbox" class="left-checkbox"
                                [(ngModel)]="node.data.checked" />
                            <mat-icon *ngIf="type ==='asset'" [disabled]="!isAssetOwner"
                                (click)="handleEditNodeClick(node); $event.stopImmediatePropagation();"
                                style="float:right;" id="edit" svgIcon="pencil_outline"></mat-icon>

                            <mat-icon *ngIf="type ==='asset'" [disabled]="!isAssetOwner"
                                (click)="handleDeleteNodeClick(node); $event.stopImmediatePropagation();"
                                style="float:right;color:red" id="delete" matTooltip="delete label">delete_outline
                            </mat-icon>

                        </div>

                    </div>
                </div>
            </ng-template>
        </tree-root>
    </div>
</div>


<!-- ---Upload Base Component--- -->
<ng-container *ngIf="!baseFile">
    <div class="text-center justify-content-center w-100 align-items-center mt-5" *ngIf="isProcessing">
        <mat-spinner style="left: 50%;" [diameter]="50"></mat-spinner>
        Please wait... we are processing
    </div>

    <div *ngIf="!isProcessing" class="d-flex justify-content-center align-items-center flex-column mt-5">
        <img src="../../assets/empty-project.svg" alt="ADD 3D">
        <button [disabled]="isImageUploading$.value || !isAssetOwner || isShapeFileProcessing$.value" mat-raised-button
            color="primary" (click)="handleAdd()" class="mb-0 mt-5">
            <span class="text-uppercase">Add your {{asset.assetType !== solar ? "technical drawing" : "DXF file /
                Shape Zip"}}
            </span>
        </button>
        <span class="mt-2 p-2" *ngIf="asset.assetType === solar">
            Please upload a dxf file or shape file zip with geo reference for automatic data linking of field data
            to the panel location labels.
            <br>
            Non geo-referenced dxf or shape files will need to be manually linked
        </span>
        <span class="d-flex" *ngIf="isImageUploading$ | async">
            <mat-spinner [diameter]="20"></mat-spinner>
            Please wait...file is uploading
        </span>
        <span class="d-flex" *ngIf="isShapeFileProcessing$ | async">
            <mat-spinner [diameter]="20"></mat-spinner>
            Please wait...shape file is processing
        </span>

        <span class="d-flex" *ngIf="kmlFile && (kmlFile.shpfile_conversion_status === failed)" style="color:#e72222">
            Conversion of shape file to kmz is failed, please contact to support.
        </span>

    </div>
</ng-container>

<input type="file" (click)="$event.target.value = null" accept="image/*,.kml,.kmz" (change)="fileChoose($event)" hidden
    id="tech_image" />

<input type="file" (click)="$event.target.value = null" accept=".dxf,.zip" (change)="fileChoose($event)" hidden
    id="tech_dxf_image" />


<ng-template #deleteLabelDialog let-data>
    <div class="popup-header w-100 d-flex">
        <p class="w-100 d-flex">
            <mat-icon style="color: #F2C94C;" class="mr-2">warning</mat-icon>
            <span>Are you absolutely sure?</span>
        </p>
        <mat-icon class="close-btn" (click)="deleteLabelDialogRef.close()">close</mat-icon>
    </div>
    <mat-dialog-content>
        <div>
            <p>This action <b>cannot</b> be undone. This will permanently delete all your <b>linked images</b>
                across
                all the projects of that asset.</p>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="w-100 ml-2 mr-2 align-items-center justify-content-end mt-3">
            <button *ngIf="!data.isDXF" color="warn" mat-button mat-stroked-button class="deleteDialog-button"
                (click)="deleteLabel(data.label)">
                Confirm
            </button>
            <button *ngIf="data.isDXF" color="warn" mat-button mat-stroked-button class="deleteDialog-button"
                (click)="deleteNodeLabel(data.node)">
                Confirm
            </button>

        </div>
    </div>

</ng-template>

<ng-template #dxfDialog let-data>
    <div class="popup-header w-100 d-flex">
        <p class="w-100 d-flex">
            <span> ({{data.header}})</span>
        </p>
        <mat-icon class="close-btn" (click)="dxfDialogRef.close()">close</mat-icon>
    </div>
    <mat-dialog-content>
        <ng-container *ngFor="let name of data.structure;let i= index">
            <span class="divider mx-1">&gt;</span>
            <span><b>{{ name }}</b></span>
        </ng-container>
        <div>
            <!-- {{data.name}} -->
        </div>
        <!-- <mat-form-field class="w-100 mb-2" appearance="fill">
            <input matInput cdkFocusInitial [(ngModel)]="data.name" [placeholder]="data.header" autocomplete="false" />
        </mat-form-field> -->
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="w-100 ml-2 mr-2 align-items-center justify-content-end mt-3">
            <button color="primary" class="btn w-100" mat-raised-button [disabled]="!data.name || data.isProcessing"
                (click)="createShape(data)">
                Save
            </button>

        </div>
    </div>

</ng-template>

<ng-template #georeferenceDialog let-data>
    <div class="popup-header w-100 d-flex">
        <p class="w-100 d-flex">
            <span>Geo Reference for panel</span>
        </p>
        <mat-icon class="close-btn" (click)="georeferenceDialogRef.close()">close</mat-icon>
    </div>
    <mat-dialog-content>
        <mat-form-field class="w-100 mb-2" appearance="fill">
            <input matInput cdkFocusInitial [(ngModel)]="data.latitude" placeholder="latitude" autocomplete="false" />
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-content>
        <mat-form-field class="w-100 mb-2" appearance="fill">
            <input matInput cdkFocusInitial [(ngModel)]="data.longitude" placeholder="longitude" autocomplete="false" />
        </mat-form-field>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="w-100 ml-2 mr-2 align-items-center justify-content-end mt-3">
            <button color="primary" class="btn w-100" mat-raised-button [disabled]="!data.longitude || data.latitude"
                (click)="savePoints(data)">
                Save Points
            </button>

        </div>
    </div>

</ng-template>


<ng-template #geoPointsDialog let-data>
    <div class="popup-header w-100 d-flex">
        <p class="w-100 d-flex">
            <span>Geo references are missings in DXF</span>
        </p>
    </div>
    <mat-dialog-content>
        <div>
            <p>
                <span>
                    geo-referenced are missings in the dxf file, for automatic linking process we need the
                    geoposition data.
                </span>
                <br>
                <span>
                    <b> Do you want to process manual marked 5- 10 points on the dxf?
                    </b>

                </span>
            </p>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="w-100 ml-2 mr-2 align-items-center justify-content-end mt-3">
            <div class="d-flex justify-content-end">
                <button mat-raised-button color="primary" (click)="openGeoPanelHint()" class="btn ml-3">Yes</button>
                <button mat-raised-button color="warn" (click)="closePanel()" class="btn">No</button>

            </div>

        </div>
    </div>

</ng-template>

<ng-template #renameDialog let-data>
    <div class="popup-header w-100 d-flex">
        <p class="w-100 d-flex">
            <span>Rename</span>
        </p>
        <mat-icon class="close-btn" (click)="renameDialogRef.close()">close</mat-icon>

    </div>
    <mat-dialog-content>
        <div>
            <mat-form-field class="w-100 mb-2" appearance="fill">
                <mat-label>Label name</mat-label>
                <input matInput cdkFocusInitial [(ngModel)]="data.node.data.name" placeholder="name" />
            </mat-form-field>
        </div>
    </mat-dialog-content>

</ng-template>

<ng-template #levelDialog let-data>
    <div class="popup-header w-100 d-flex">
        <p class="w-100 d-flex">
            <span>Name of Level</span>
        </p>
        <mat-icon class="close-btn" (click)="levelDialogRef.close()">close</mat-icon>

    </div>
    <mat-dialog-content>
        <div>
            <mat-form-field class="w-100 mb-2" appearance="fill">
                <mat-label>level name</mat-label>
                <input matInput cdkFocusInitial [(ngModel)]="data.name" placeholder="name" />
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <div class="w-100 ml-2 mr-2 align-items-center justify-content-end mt-3">
            <button color="primary" class="btn w-100" mat-raised-button [disabled]="!data.name"
                (click)="saveLevel(data)">
                Save
            </button>

        </div>
    </div>

</ng-template>